import { getRequest } from 'utils/http';
import { mapDataToClient } from './mapData';
import { ChannelsCollection } from './types';


export async function getChannelsAPI(externalProductId: string) {
    const url = `/tv-channels?externalProductId=${externalProductId}`;

    return getRequest<ChannelsCollection>(url, { mapData: mapDataToClient });
}
