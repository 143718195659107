import React from 'react';
import Popup from 'reactjs-popup';
import { CheckMark, Error, Close } from 'assets/icons';
import { Text } from 'components/typography';
import { ShouldRender } from 'components/ShouldRender/ShouldRender';
import { Flex } from 'components/layout';
import { Button } from 'components/form-elements';
import { useModal } from 'store/modals';

interface ModalProps {
    type?: 'success' | 'error',
    message?: string,
}

export const DefaultModal: React.FC<ModalProps> = ({ type, message }) => {

    const { closeModal } = useModal();

    return(
        <Popup
            open
            modal
            closeOnDocumentClick = {false}
            contentStyle={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0 3px 9px 0 #7070707d',
                padding: '4em',
                width: '35em',
            }}
        >
            <Button
                sx={{
                    position: 'absolute',
                    display: 'block',
                    right: '-10px',
                    top: '-10px',
                    borderRadius: '50%',
                    padding: '8px 12px'
                }}
                onClick={closeModal}
            >
                <Close />
            </Button>

            <Flex
                flexDirection='column'
                alignItems='center'
                gap={30}
            >
                <ShouldRender condition={type === 'success'}>
                    <CheckMark size={40}/>
                </ShouldRender>

                <ShouldRender condition={type === 'error'}>
                   <Error size={40}/>
                </ShouldRender>

                <Text as='h3'>
                    {message}
                </Text>
            </Flex>
        </Popup>
)};



