import React from 'react';
import * as ThemeUI from 'theme-ui';
import { CardVariants } from 'theming/variants/card';

interface CardProps extends ThemeUI.CardProps {
    variant?: CardVariants;
    children?: React.ReactNode;
    isClickable?: boolean;
    onClick?: React.MouseEventHandler;
}

export const Card: React.FC<CardProps> = ({isClickable, onClick, children, ...props }) => (
    <ThemeUI.Card
        onClick={onClick}
        sx={{
            cursor: isClickable ? 'pointer' : 'default'
        }}
        {...props}
    >
        {children}
    </ThemeUI.Card>
);
