import React from "react";
import { useModal } from 'store/modals';
import { Modals } from './modals';

interface ModalTypes {
  DefaultModal?: string,
}

export const ModalContainer: React.FC = () => {
  const { modalType, modalProps } = useModal();

  if (!modalType) {
    return null;
  }

  const OpenedModal = Modals[modalType as keyof ModalTypes];

  return (
    <OpenedModal {...(typeof modalProps === 'object' ? modalProps : {})} />
  );
};
 
