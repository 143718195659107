import React from 'react';
import * as ThemeUI from 'theme-ui';
import { foundations } from './foundations';
import { variants } from './variants';

type ThemeProviderProps = {
  children?: React.ReactNode
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => (
  <ThemeUI.ThemeProvider theme={{ ...foundations, ...variants }}>
    {children}
  </ThemeUI.ThemeProvider>
);
