import React from 'react';
import { generateContext } from './generateContext';

type StoreProviderProps = {
    children?: React.ReactNode
};

export function createStoreProvider<T>(useHook: () => T) {
    const [useContextConsumer, ContextProvider] = generateContext<T>();

    const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
        const state = useHook();

        return <ContextProvider value={state}>{children}</ContextProvider>;
    };

    return [useContextConsumer, StoreProvider] as const;
}
