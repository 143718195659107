export const colors = {
  'text-primary': '#3a3a3a',

  error: '#ff0000',
  success: '#5f901c',

  bg: {
    0: '#fff',
    100: '#f3f3f3',
    200: '#eeeef0',
    300: '#dddddd',
    400: '#f0f0f0',
    500: '#cfcfcf',
    600: '#707070',
    800: '#3e3e3e',
  },
};
