function mapSearchParamsToObject(searchParams: URLSearchParams) {
    const url = document.URL;
    const code = url.split('code=')[1];

    const result: Record<string, string> = {};

    searchParams.forEach((value, key) => {
        if (key === 'code') result[key] = code
        else result[key] = value;
    });

    return result;
}

export function parseQuery(queryString: string) {
    return mapSearchParamsToObject(new URLSearchParams(queryString));
}
