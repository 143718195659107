export type CardVariants = 'main' | 'channel'

export const cards = {
    main: {
        backgroundColor: 'bg.300',
        boxShadow: '0 3px 9px 0 #7070707d',
        padding: '1em',
        minHeight: '80vh',
    },
    channel: {
        backgroundColor: 'bg.0',
        padding: '1em',
        width: '170px',
        height: '170px',
    },
};
