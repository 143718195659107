import React from 'react';
import * as ThemeUI from 'theme-ui';
import { ButtonVariants } from 'theming/variants/button';

interface ButtonProps extends ThemeUI.ButtonProps {
  variant?: ButtonVariants;
  isLoading?: boolean; 
  disabled?: boolean;
  size?: string | number;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  isLoading,
  disabled,
  size, 
  onClick,
  sx,
  children,
  ...props
}) => (
  <ThemeUI.Button
    sx={{
      cursor: isLoading ? 'wait' : disabled ? 'not-allowed' : 'pointer',
      size,
      ...sx,
    }}
    type={type}
    disabled={disabled || isLoading}
    onClick={onClick}
    {...props}
  >
    {children}
  </ThemeUI.Button>
);

