import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { updateUserChannelsAPI } from './api';
import { UpdateUserChannelsRequest } from './types';
import { useModal } from "store/modals";

const USER_CHANNELS = 'userChannels';

export function useUpdateUserChannels() {
    const queryClient = ReactQuery.useQueryClient();
    const { openModal } = useModal();

    const { mutate, isLoading, isError } = ReactQuery.useMutation<void, ErrorType, UpdateUserChannelsRequest>(
        updateUserChannelsAPI,
        {
            onSuccess() {
                openModal({
                    modalType: 'DefaultModal',
                    modalProps: {
                        type: 'success',
                        message: 'Ditt val av Flex-kanaler är nu inkluderat i ditt paket.',
                    },
                });
                queryClient.invalidateQueries(USER_CHANNELS);
            },
            onError() {
                openModal({
                    modalType: 'DefaultModal',
                    modalProps: {
                        type: 'error',
                        message: 'Något gick fel. Var god försök igen senare.',
                    },
                });
            }
        }
    );

    return {
        updateUserChannels: mutate,
        isLoading,
        isError,
    };
}
