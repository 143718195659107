import React from 'react';
import * as ThemeUI from 'theme-ui';
import { ButtonVariants } from 'theming/variants/button';

export interface IconButtonProps extends ThemeUI.ButtonProps {
  size? : string | number;
  variant? : ButtonVariants; 
  disabled? : boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

export const IconButton: React.FC<IconButtonProps> = ({
  size, 
  onClick,
  sx,
  children,
  ...props
}) => (
  <ThemeUI.IconButton
    sx={{
      size,
      ...sx,
    }}
    type='button'
    onClick={onClick}
    {...props}
  >
    {children}
  </ThemeUI.IconButton>
);

