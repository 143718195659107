export function getFlex(external_product_id: string) {
    switch (external_product_id){
        case '599':
        case '201':
            return 7;
        case '598':
            return 14;
        default:
            return undefined;
    }
}
