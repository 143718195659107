import React from 'react';
import { Flex, Grid } from 'components/layout';
import { useChannels } from 'services/channels/get-channels';
import { Card } from 'components/cards';
import { Button, Form } from 'components/form-elements';
import { Loader } from 'components/Loader/Loader';
import { ShouldRender } from 'components/ShouldRender/ShouldRender';
import { FlexMenu } from 'components/FlexMenu/FlexMenu';
import { useSearchQuery } from 'utils/url';
import { getFlex } from 'utils/tv-channels';
import { useUserChannels } from 'services/channels/get-user-channels';
import { Channel, ChannelsCollection } from 'services/channels/get-channels/types';
import { TVChannelsHeader, TVChannel } from './components';
import { useUpdateUserChannels } from "services/channels/update-user-channels";
import { UpdateUserChannelsRequest } from "services/channels/update-user-channels/types";

export const TVChannelsPicker: React.FC = () => {
    const searchQuery = useSearchQuery();
    const { channels, isLoading: isChannelsLoading } = useChannels(searchQuery.external_product_id);
    const { userChannels, isLoading: isUserChannelsLoading } = useUserChannels(searchQuery.subscription_id, searchQuery.external_product_id, searchQuery.ts, searchQuery.code);
    const { updateUserChannels, isLoading: isUpdateLoading } = useUpdateUserChannels();
    const [ selectedChannels, setSelectedChannels ] = React.useState<ChannelsCollection>();
    const flex = getFlex(searchQuery.external_product_id);

    React.useEffect(() => {
        setSelectedChannels(userChannels);
    }, [userChannels]);

    function onChannelClick (channel: Channel) {
        if (selectedChannels) {
            if (selectedChannels.some(item => item.id === channel.id)) setSelectedChannels(selectedChannels.filter((item) => item.id !== channel.id));
            else if (flex && selectedChannels.length < flex) setSelectedChannels([...selectedChannels, channel]);
        } else if (flex) setSelectedChannels([channel]);
    }

    function onSubmit() {
        const channelsToAdd = selectedChannels?.filter(item => !userChannels?.includes(item));
        const channelsToRemove = userChannels?.filter(item => !selectedChannels?.includes(item));

        const data: UpdateUserChannelsRequest = {
            channels: {
                'channelsToAdd': channelsToAdd || [],
                'channelsToRemove': channelsToRemove || []
            },
            'subscriptionId': searchQuery.subscription_id,
            'externalProductId': searchQuery.external_product_id,
            'ts': searchQuery.ts,
            'code': searchQuery.code
        }
        updateUserChannels(data);
    }

    return (
        <Card variant='main'>
            <Form onSubmit={onSubmit}>
                <Flex
                    flexDirection='column'
                    p='1em'
                    gap={30}
                >
                    <TVChannelsHeader flex={flex} />

                    <FlexMenu
                        flex={flex}
                        channelsCount={selectedChannels?.length}
                    />

                    {(isChannelsLoading || isUserChannelsLoading) ? <Loader /> : (
                        <ShouldRender condition={!!channels}>
                            <Grid
                                sx={{
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
                                    justifyItems: 'center',
                                    '@media screen and (max-width: 470px)': {
                                        gridTemplateColumns: 'none',
                                    },
                                }}
                            >
                                {
                                    channels?.map((channel) => (
                                        <TVChannel
                                            key={channel.id}
                                            name={channel.name}
                                            description={channel.description}
                                            image={channel.imageUrl}
                                            isActive={selectedChannels?.some(item => item.id === channel.id)}
                                            onClick={() => onChannelClick(channel)}
                                            isClickable={selectedChannels && flex ? (
                                                selectedChannels?.length < flex || selectedChannels?.some(item => item.id === channel.id))
                                                : false}
                                            flex={flex}
                                        />
                                    ))
                                }
                            </Grid>

                            <ShouldRender condition={!!flex}>
                                <Flex justifyContent='flex-end'>
                                    <Button
                                        variant='primary'
                                        type='submit'
                                        isLoading={isUpdateLoading}
                                    >
                                        Skicka
                                    </Button>
                                </Flex>
                            </ShouldRender>
                        </ShouldRender>
                    )}
                </Flex>
            </Form>
        </Card>
    );
};
