import React from 'react';
import { Routes } from 'app/Routes';
import { GlobalProvider } from 'app/GlobalProvider';
import { ModalContainer } from 'components/ModalContainer';

const App: React.FC = () => (
    <GlobalProvider>
        <Routes />

        <ModalContainer/>
    </GlobalProvider>
)
export default App;
