import React from 'react';
import { Container, Grid } from 'components/layout';
import { TVChannelsMenu } from 'components/TVChannelsMenu/TVChannelsMenu';
import { TVChannelsPicker } from 'components/TVChannelsPicker/TVChannelsPicker';

export const Home: React.FC = () => (
    <Container variant='page'>
        <Grid
            columns={[null, null, null, '30% 70%']}
        >
            <TVChannelsMenu />

            <TVChannelsPicker />
        </Grid>
    </Container>
);
