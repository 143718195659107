import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { Routing } from 'global/routing';
import * as Pages from 'pages';

export const Routes: React.FC = () => (
    <ReactRouterDom.Routes>
        <ReactRouterDom.Route
            path={Routing.HOME.getPath()}
            element={<Pages.Home />}
        />
    </ReactRouterDom.Routes>
);
