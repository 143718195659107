import * as _ from 'lodash-es';

const appendPath = (...paths: (string|number)[]) => _.join(paths, '/');

const HOME = {
    getPath: (): string => appendPath('/'),
};

export const Routing = {
    HOME
};
