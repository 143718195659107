import React from 'react';
import TVProviderImg from 'assets/images/tv-provider.png';
import { Text } from 'components/typography';
import { Flex } from 'components/layout';
import { Image } from 'components/media';

export const TVChannelsMenu: React.FC = () => (
    <Flex
        flexDirection='column'
        gap={30}
    >
        <Text as='h1'>
            TV Kanaler
        </Text>

        <Image
            src={TVProviderImg}
            width={250}
            alt='TV Provider'
            sx={{alignSelf: 'center'}}
        />
    </Flex>
);
