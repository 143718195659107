import React from "react";
import LjungbiEnergyLogoImg from 'assets/images/ljungbi-energy-logo.svg';
import { Flex } from 'components/layout';
import { Image } from 'components/media';
import { ShouldRender } from 'components/ShouldRender/ShouldRender';
import { Text } from 'components/typography';
import { getFlexDescription } from "utils/tv-channels";

export interface HeaderProps {
    flex?: 7 | 14;
}

export const TVChannelsHeader: React.FC<HeaderProps> = ({ flex }) => (
    <Flex
        flexDirection='row'
        gap={10}
        p={20}
        alignItems='center'
        sx={{
            backgroundColor: 'bg.0',
            '@media screen and (max-width: 525px)': {
                flexDirection: 'column',
            },
        }}
    >
        <Image
          src={LjungbiEnergyLogoImg}
          alt='Ljungbi Energy'
          sx={{
              minWidth:'fit-content',
              minHeight:'fit-content'
          }}
        />

        <Flex
            flexDirection='column'
            gap={10}
        >
            <ShouldRender condition={!!flex}>
                <Text
                    as='h3'
                    textTransform='uppercase'
                >
                  Flex: {flex}
                </Text>

                <Text>{ getFlexDescription(flex) }</Text>

                <Text sx={{ fontWeight: 800 }}>
                    OBS! det kan ta upp till 2 timmar innan du kan se kanalerna på din tv-apparat. Låt tv:n stå på för att ditt programkort ska ta emot uppdateringarna.
                </Text>
            </ShouldRender>

            <ShouldRender condition={!flex}>
                <Text>{ getFlexDescription(flex) }</Text>
            </ShouldRender>
        </Flex>
    </Flex>
);
