import React from 'react';
import { CheckMark } from 'assets/icons';
import { IconButton } from 'components/form-elements';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { ShouldRender } from 'components/ShouldRender/ShouldRender';

interface FlexMenuProps {
    flex?: 7 | 14;
    channelsCount?: number;
}

export const FlexMenu: React.FC<FlexMenuProps> = ({ flex, channelsCount }) => (
    <Flex
        justifyContent='space-between'
        flexWrap='wrap'
        gap={3}
    >
        <Text
            as='h3'
            textTransform='uppercase'
        >
            Kanaler
        </Text>

        <ShouldRender condition={!!flex}>
            <Flex
                flexDirection='row'
                gap={3}
                flexWrap='wrap'
                sx={{
                    maxWidth: '306px',
                }}
            >
                {[...Array(flex)].map((e, i) => ((channelsCount && i < channelsCount)
                    ? (
                        <CheckMark
                            key={i}
                            size={30}
                        />
                    )
                    : (
                        <IconButton
                            key={i}
                            variant='disabled'
                            size={30}
                        />
                    )))}
            </Flex>
        </ShouldRender>
    </Flex>
);
