import { layout } from './layout';
import { buttons } from './button';
import { cards } from './card';
import { root } from './root';

export const variants = {
  layout,
  buttons,
  cards,
  styles: {
    root,
  },
};
