import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { ThemeProvider } from 'theming';
import { ServiceProvider } from 'services';
import { StoreProvider } from 'store';

type GlobalProviderProps = {
    children?: React.ReactNode
};

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => (
    <ReactRouterDom.BrowserRouter>
        <ServiceProvider>
            <StoreProvider>
                <ThemeProvider>
                    {children}
                </ThemeProvider>
            </StoreProvider>
        </ServiceProvider>
    </ReactRouterDom.BrowserRouter>
);
