import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { getUserChannelsAPI } from './api';
import { UserChannelsCollection } from './types';

const USER_CHANNELS = 'userChannels';

export function useUserChannels(subscriptionId: string, externalProductId: string, ts: string, code: string) {
    const {
        data,
        isLoading,
        isFetching,
        isError,
    } = ReactQuery.useQuery<UserChannelsCollection, ErrorType>(
        USER_CHANNELS,
        () => getUserChannelsAPI(subscriptionId,externalProductId, ts, code),
        {
            enabled: !!externalProductId
        }
    );

    return {
        userChannels: data,
        isLoading: isLoading || isFetching,
        isError,
    };
}
