import React from 'react';
import * as ThemeUI from 'theme-ui';
import { Flex } from 'components/layout';

export const Loader: React.FC = () => (
    <Flex justifyContent='center'>
        <ThemeUI.Spinner
            color='bg.8'
        />
    </Flex>
  );

