import React from 'react';
import Popup from 'reactjs-popup';
import { Image } from 'components/media';
import { Text } from 'components/typography';
import { Grid } from 'components/layout';

interface TooltipProps {
    children?: React.ReactNode;
    trigger: React.ReactNode;
    header?: string,
    image?: string,
    content?: string,
}

export const Tooltip: React.FC<TooltipProps> = ({trigger, header, image, content }) => (
    <Popup
        trigger= {( <div> {trigger} </div> )}
        on={['hover', 'focus']} 
        position={['top center', 'bottom center', 'center center']}
        arrow={false}
        contentStyle={{
            background: '#fff',
            width: '40%',
            minHeight: '200px',
            boxShadow: '1px 0px 10px 1px #cfcfcf',
            padding: '2em'
        }}
    >
        <Text as='h5'>
            {header}
        </Text>

        <Grid columns={[null, null, null, '30% 70%']}>
            <Image
                src={image}
                width={100}
                padding={10}
            />

            <Text fontSize={14}>
                {content}
            </Text>
        </Grid>
    </Popup>
);

