export function getFlexDescription(flex: number | undefined) {
    switch (flex){
        case 7:
            return 'Paketet som ger dig möjlighet att välja 7 kanaler per dag. Välj mellan ett 40-tal kanaler. Flex7 har 1 kalendermånads bindningstid, du kan byta kanaler en gång per dag. Markera de kanaler du vill se just idag och klicka beställ.';
        case 14:
            return 'Suveräna tilläggspaketet Flex14, där du kan byta ut 14 kanaler om dagen. Välj mellan ett 40-tal kanaler. Flex14 har 1 kalendermånads bindningstid, du kan byta kanaler en gång per dag. Markera de kanaler du vill se just idag och klicka beställ eller ändra.';
        default:
            return 'Ljungby Energi erbjuder ett stort urval av TV-kanaler, nedan kan du se vilka kanaler som du kan välja via tjänsterna Flex-7 eller Flex-14. Du väljer enkelt mellan olika kanaler och kan även byta vilka kanaler du föredrar.';
    }
}
