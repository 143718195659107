import { IconButtonProps } from "components/form-elements";

export const CheckMark = ({ size = 20 }: IconButtonProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.715 14.715"
            width={size}
        >
            <path
                d="M7.358,0a7.358,7.358,0,1,0,7.358,7.358A7.358,7.358,0,0,0,7.358,0ZM11.6,5.028l-4.2,5.7a.62.62,0,0,1-.877.115l-3-2.4A.613.613,0,0,1,4.3,7.484l2.5,2L10.618,4.3a.613.613,0,1,1,.987.725Z"
                fill='#5f901c'
            />
        </svg>
    );
};
