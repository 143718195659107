import React from 'react';
import { ModalProvider } from './modals';

type StoreProps = {
  children: React.ReactNode; 
};

export const StoreProvider: React.FC<StoreProps> = ({ children }) => (
    <ModalProvider>
      {children}
    </ModalProvider>
);
