import { IconButtonProps } from "components/form-elements";

export const Close = ({ size = 10 }: IconButtonProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
             width={size}
            viewBox="0 0 10.829 10.828"
        >
            <g
                id="Group_11733"
                transform="translate(6687.415 -16616.453)"
            >
                <g
                    id="Group_11731"
                    transform="translate(-6702 16601.867)"
                >
                    <path
                        id="Path_11645"
                        d="M7552.179-21720.914l8,8"
                        transform="translate(-7536.18 21736.914)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />

                    <path
                        id="Path_11646"
                        d="M0,0,8,8"
                        transform="translate(23.999 16) rotate(90)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};
