import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { getChannelsAPI } from './api';
import { ChannelsCollection } from './types';

const CHANNELS = 'channels';

export function useChannels(externalProductId: string) {
    const {
        data,
        isLoading,
        isError,
    } = ReactQuery.useQuery<ChannelsCollection, ErrorType>(
        CHANNELS,
        () => getChannelsAPI(externalProductId),
    );

    return {
        channels: data,
        isLoading,
        isError,
    };
}
