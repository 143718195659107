export type ButtonVariants = 'primary' | string;

export const buttons: any = {
    primary: {
        backgroundColor: 'bg.800',
        '&:disabled': {
            backgroundColor: 'bg.600'
        },
    },
    secondary: {
        backgroundColor: 'bg.500',
        borderRadius: '50%'
    },
    disabled: {
        backgroundColor: 'bg.0',
        borderRadius: '50%'
    },
};
