import React from 'react';
import { CheckMark } from "assets/icons";
import { Card } from 'components/cards'
import { ShouldRender } from 'components/ShouldRender/ShouldRender';
import { Image } from 'components/media';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { IconButton } from "components/form-elements";
import { Tooltip } from 'components/Tooltip/Tooltip';


interface TVChannelProps {
    id?: string;
    name?: string;
    image?: string;
    description?: string;
    isActive?: boolean
    onClick?: React.MouseEventHandler;
    isClickable: boolean
    flex?: 7 | 14;
}

export const TVChannel: React.FC<TVChannelProps> = ({image, name, description, isActive, onClick, flex, isClickable}) => (
    <Tooltip
        trigger = {(
            <Card
                variant='channel'
                isClickable={isClickable}
                onClick={onClick}
            >
                <ShouldRender condition={!!flex}>
                    <ShouldRender condition={!!isActive}>
                        <Flex justifyContent='flex-end'>
                            <CheckMark />
                        </Flex>
                    </ShouldRender>

                    <ShouldRender condition={!isActive}>
                        <Flex justifyContent='flex-end'>
                            <IconButton
                                variant='secondary'
                                size={20}
                            />
                        </Flex>
                    </ShouldRender>
                </ShouldRender>

                <Text
                    as='h6'
                    textTransform='uppercase'
                >
                    {name}
                </Text>

                <ShouldRender condition={!!image}>
                    <Flex justifyContent='center'>
                        <Image
                            src={image}
                            width={100}
                            p={10}
                        />
                    </Flex>
                </ShouldRender>
            </Card>
        )}
        header={name}
        content={description}
        image={image}
    />
);
